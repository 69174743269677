import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BaseAppLayout } from '../components/BaseAppLayout';
import { Assessment } from '../pages/assessment';
import { AssessmentQuestion } from '../pages/assessment/[slug]';
import { Councelor } from '../pages/councelor';
import { Events } from '../pages/events';
import { Home } from '../pages/home';
import { OnlineCouncellor } from '../pages/onlineCouncellor';
import { Register } from '../pages/register';
import { Research } from '../pages/research';
import { ResearchDetails } from '../pages/research/[slug]';
import { UpcomingEvents } from '../pages/upcomingEvents';
import { VentingStation } from '../pages/ventingStation';

export const Routes: React.FunctionComponent = () => {
  function renderWithLayout(Page: any, Layout: any) {
    return (
      <Layout>
        <Page />
      </Layout>
    );
  }

  function SecuredRoute(props: any) {
    const { path } = props;
    return (
      <Route
        path={path}
        render={
          (data) => (
            // getSession(props.routeroles) ? (
            <props.component {...data} />
          )
          // ) : (
          //   <Redirect to={{ pathname: '/' }} />
          // )
        }
      />
    );
  }
  return (
    <Switch>
      <SecuredRoute
        exact
        path="/"
        component={() => renderWithLayout(Home, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/councelor"
        component={() => renderWithLayout(Councelor, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/upcomingevents"
        component={() => renderWithLayout(UpcomingEvents, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/events"
        component={() => renderWithLayout(Events, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/ventingstation"
        component={() => renderWithLayout(VentingStation, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/onlinecouncellor"
        component={() => renderWithLayout(OnlineCouncellor, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/register"
        component={() => renderWithLayout(Register, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/research"
        component={() => renderWithLayout(Research, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/research/:slug"
        component={() => renderWithLayout(ResearchDetails, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/assessment"
        component={() => renderWithLayout(Assessment, BaseAppLayout)}
      />
      <SecuredRoute
        exact
        path="/assessment/:slug"
        component={() => renderWithLayout(AssessmentQuestion, BaseAppLayout)}
      />
    </Switch>
  );
};
