import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const enum ResearchCard {
  Profile = 'Profiles Of Guides',
  Help = 'Help Us Research',
  Variables = 'Variables',
  Research = 'Research Articles',
  Meteorologist = 'Research Meteorologist & Updating',
  Firms = 'Research For Firms',
  Guides = 'Profiles of Guides',
}

export const ResearchDetails: FC = () => {
  const history = useHistory();
  const { slug } = useParams() as { slug: string };
  const [heading, setHeading] = useState<String>('');
  const [Image, setImage] = useState<String>('');
  const [isPopup, setIsPopup] = useState<boolean>(false);

  useEffect(() => {
    switch (slug) {
      case '1':
        setHeading(ResearchCard.Profile);
        setImage('/assets/Image/400x400/07.jpg');
        break;
      case '2':
        setHeading(ResearchCard.Help);
        setImage('/assets/Image/400x400/08.jpg');
        break;
      case '3':
        setHeading(ResearchCard.Variables);
        setImage('/assets/Image/400x400/09.jpg');
        break;
      case '4':
        setHeading(ResearchCard.Research);
        setImage('/assets/Image/400x400/10.jpg');
        break;
      case '5':
        setHeading(ResearchCard.Meteorologist);
        setImage('/assets/Image/400x400/11.jpg');
        break;
      case '6':
        setHeading(ResearchCard.Firms);
        setImage('/assets/Image/400x400/12.jpg');
        break;
      default:
        setHeading(ResearchCard.Guides);
        setImage('/assets/Image/400x400/07.jpg');
        break;
    }
  }, [slug]);

  return (
    <>
      <div className="s-swiper js__swiper-one-item">
        <div className="swiper-wrapper">
          <div className="carousel-Width">
            <>
              <div className="g-fullheight--xs g-bg-position--center swiper-slide banner-image2">
                <div className="container g-text-center--xs g-ver-center--xs">
                  <div className="g-margin-b-30--xs">
                    <h2 className="g-font-size-35--xs g-font-size-45--sm g-font-size-55--md g-color--white">
                      {heading}
                    </h2>
                  </div>
                </div>
              </div>
              <a
                href="#research-details"
                className="s-scroll-to-section-v1--bc g-margin-b-15--xs"
              >
                <span className="g-font-size-18--xs g-color--white ti-angle-double-down"></span>
                <p className="text-uppercase g-color--white g-letter-spacing--3 g-margin-b-0--xs">
                  Learn More
                </p>
              </a>
            </>
          </div>
        </div>
        <button
          type="button"
          onClick={() => history.push('/research')}
          className="s-swiper__arrow-v1--left s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-left js__swiper-btn--prev"
        />
        <button
          type="button"
          onClick={() => history.push('/assessment')}
          className="s-swiper__arrow-v1--right s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-right js__swiper-btn--next"
        />
      </div>
      <div className="cont_bg" id="research-details">
        <div className="container g-padding-y-80--xs g-padding-y-125--sm g-margin-b-25--xs">
          <div className="profile-outer g-margin-b-60--xs">
            <div className="row g-hor-centered-row--md">
              <div className="col-md-8 g-hor-centered-row__col g-margin-b-60--xs g-margin-b-0--md">
                <div className="g-width-100-percent--xs g-margin-b-40--xs">
                  <h2 className="g-font-size-32--xs g-font-size-36--md g-font-family--playfair g-margin-b-20--xs">
                    {heading}
                  </h2>
                  <p className="g-font-size-16--sm">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-xs-6 col-xs-offset-3 g-full-width--xs g-full-width-offset-0--xs g-hor-centered-row__col">
                <div
                  className="wow fadeInUp"
                  data-wow-duration=".3"
                  data-wow-delay=".1s"
                >
                  <img
                    className="img-responsive"
                    src={Image as string}
                    alt="Image1"
                  />
                </div>
              </div>
            </div>
          </div>
          {heading === ResearchCard.Profile && (
            <>
              <div className="row g-row-col--5">
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box  g-padding-y-40--xs g-border-color--cout">
                      <div className="clearfix ">
                        <div className="g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/05.jpg"
                            alt="Image1"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box g-padding-y-40--xs g-border-color--blueshrt">
                      <div className="clearfix ">
                        <div className=" g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/06.jpg"
                            alt="Image2"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box g-padding-y-40--xs g-border-color--drhosp">
                      <div className="clearfix ">
                        <div className=" g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/03.jpg"
                            alt="Image3"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box g-padding-y-40--xs g-border-color--cout">
                      <div className="clearfix ">
                        <div className=" g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/01.jpg"
                            alt="Image4"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box g-padding-y-40--xs g-border-color--fmodel">
                      <div className="clearfix ">
                        <div className=" g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/02.jpg"
                            alt="Image5"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box g-padding-y-40--xs g-border-color--tshrt">
                      <div className="clearfix ">
                        <div className=" g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/04.jpg"
                            alt="Image6"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box  g-padding-y-40--xs g-border-color--blksht">
                      <div className="clearfix ">
                        <div className=" g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/05.jpg"
                            alt="Image7"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs g-text-center--xs">
                  <div
                    className="wow fadeInUp"
                    data-wow-duration=".3"
                    data-wow-delay=".1s"
                  >
                    <div className="s-plan-v1 councellor-box g-padding-y-40--xs g-border-color--blueshrt">
                      <div className="clearfix ">
                        <div className=" g-text-center--xs">
                          <img
                            className="g-width-250--xs g-height-250--xs g-box-shadow__dark-lightest-v4"
                            src="/assets/Image/400x400/06.jpg"
                            alt="Image8"
                          />

                          <h3 className="g-font-size-18--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                            Councellor Name
                          </h3>

                          <a
                            id="myBtn"
                            className="text-uppercase s-btn s-btn--xs g-text-center--xs g-bg-color--blue g-radius--50"
                            onClick={() => setIsPopup(true)}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="myModal"
                className={`modal ${isPopup ? 'modal-active' : ''}`}
              >
                <div className="modal-content container g-bg-color--sky-light">
                  <span
                    className="close"
                    aria-hidden
                    onClick={() => setIsPopup(false)}
                  >
                    &times;
                  </span>
                  <div className="row">
                    <div className="col-md-12 col-xs-6 g-margin-b-60--xs g-margin-b-0--md">
                      <div className="g-width-100-percent--xs g-margin-b-40--xs">
                        <h2 className="g-font-size-32--xs g-font-size-36--md g-margin-b-20--xs">
                          About
                        </h2>
                        <p className="g-font-size-20--sm">
                          Now that we've aligned the details, it's time to get
                          things mapped out and organized. This part is really
                          crucial in keeping the project in line to completion.
                        </p>
                        <p className="g-font-size-20--sm">
                          The time has come to bring those ideas and plans to
                          life. This is where we really begin to visualize your
                          napkin sketches and make them into beautiful pixels.
                        </p>

                        <div className="row g-margin-b-30--xs g-margin-b-50--md">
                          <div className="col-sm-9">
                            <div className="row">
                              <div className="col-sm-2 g-margin-b-30--xs g-margin-b-0--md">
                                Languages
                              </div>
                              <div className="col-sm-1 g-margin-b-30--xs g-margin-b-0--md">
                                :
                              </div>
                              <div className="col-sm-6 g-margin-b-30--xs g-margin-b-0--md">
                                English, Arabic, Malayalam
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-2 g-margin-b-30--xs g-margin-b-0--md">
                                Mode
                              </div>
                              <div className="col-sm-1 g-margin-b-30--xs g-margin-b-0--md">
                                :
                              </div>
                              <div className="col-sm-6 g-margin-b-30--xs g-margin-b-0--md">
                                English, Arabic, Malayalam
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-2 g-margin-b-30--xs g-margin-b-0--md">
                                Specialisation
                              </div>
                              <div className="col-sm-1 g-margin-b-30--xs g-margin-b-0--md">
                                :
                              </div>
                              <div className="col-sm-6 g-margin-b-30--xs g-margin-b-0--md">
                                English, Arabic, Malayalam
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-2 g-margin-b-30--xs g-margin-b-0--md">
                                Date
                              </div>
                              <div className="col-sm-1 g-margin-b-30--xs g-margin-b-0--md">
                                :
                              </div>
                              <div className="col-sm-6 g-margin-b-30--xs g-margin-b-0--md">
                                English, Arabic, Malayalam
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-2 g-margin-b-30--xs g-margin-b-0--md">
                                Avaibality
                              </div>
                              <div className="col-sm-1 g-margin-b-30--xs g-margin-b-0--md">
                                :
                              </div>
                              <div className="col-sm-6 g-margin-b-30--xs g-margin-b-0--md">
                                English, Arabic, Malayalam
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <a
                              onClick={() => {
                                setIsPopup(false);
                                history.push('/register');
                              }}
                              className="text-uppercase btn-block s-btn s-btn--md s-btn--primary-bg g-radius--50 g-padding-x-50--xs g-margin-b-20--xs"
                            >
                              Book
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {heading === ResearchCard.Help && (
            <div className="profile-outer g-margin-b-60--xs">
              <h2 className="g-font-size-32--xs g-font-size-36--md g-font-family--playfair g-margin-b-20--xs">
                Quick Contact
              </h2>
              <div className="row">
                <div className="col-sm-12 col-md-12 g-padding-b-20--xs">
                  <input
                    type="text"
                    name=""
                    className="form-control s-form-v6__input"
                    placeholder="Name"
                  />
                </div>
                <div className="col-sm-12 col-md-6 g-padding-b-20--xs">
                  <input
                    type="text"
                    name=""
                    className="form-control s-form-v6__input"
                    placeholder="Email"
                  />
                </div>
                <div className="col-sm-12 col-md-6 g-padding-b-20--xs">
                  <input
                    type="text"
                    name=""
                    className="form-control s-form-v6__input"
                    placeholder="Contact Number"
                  />
                </div>
                <div className="col-sm-12 col-md-12 g-padding-b-20--xs">
                  <textarea
                    className="form-control s-form-v6__input height-110"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="col-sm-12 col-md-12 g-padding-b-20--xs">
                  <a
                    href="#"
                    className="text-uppercase s-btn s-btn--md s-btn--white-bg g-radius--50 g-padding-x-70--xs g-margin-b-20--xs s-btn--primary-bg"
                  >
                    Send
                  </a>
                </div>
              </div>
            </div>
          )}
          {heading !== ResearchCard.Profile && heading !== ResearchCard.Help && (
            <div className="row g-row-col--15">
              <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
                <div
                  className="wow fadeInUp"
                  data-wow-duration=".3"
                  data-wow-delay=".1s"
                >
                  <div className="s-plan-v1 councellor-box research-box">
                    <div className="clearfix ">
                      <div className=" g-text-center--xs">
                        <img src="/assets/Image/400x400/07.jpg" alt="Image1" />
                        <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                          Profile
                        </h3>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <a
                          id="myBtn"
                          className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                          onClick={() => history.push('/research/1')}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
                <div
                  className="wow fadeInUp"
                  data-wow-duration=".3"
                  data-wow-delay=".1s"
                >
                  <div className="s-plan-v1 councellor-box research-box">
                    <div className="clearfix ">
                      <div className=" g-text-center--xs">
                        <img src="/assets/Image/400x400/08.jpg" alt="Image2" />

                        <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                          Help Us Research
                        </h3>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <a
                          id="myBtn"
                          className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                          onClick={() => history.push('/research/2')}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
                <div
                  className="wow fadeInUp"
                  data-wow-duration=".3"
                  data-wow-delay=".1s"
                >
                  <div className="s-plan-v1 councellor-box research-box">
                    <div className="clearfix ">
                      <div className=" g-text-center--xs">
                        <img src="/assets/Image/400x400/09.jpg" alt="Image2" />

                        <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                          Variables
                        </h3>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <a
                          id="myBtn"
                          className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                          onClick={() => history.push('/research/3')}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
                <div
                  className="wow fadeInUp"
                  data-wow-duration=".3"
                  data-wow-delay=".1s"
                >
                  <div className="s-plan-v1 councellor-box research-box">
                    <div className="clearfix ">
                      <div className=" g-text-center--xs">
                        <img src="/assets/Image/400x400/10.jpg" alt="Image4" />

                        <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                          Research Articles
                        </h3>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.Lorem Ipsum is simply dummy text
                          of the printing.
                        </p>
                        <a
                          id="myBtn"
                          className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                          onClick={() => history.push('/research/4')}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
                <div
                  className="wow fadeInUp"
                  data-wow-duration=".3"
                  data-wow-delay=".1s"
                >
                  <div className="s-plan-v1 councellor-box research-box">
                    <div className="clearfix ">
                      <div className=" g-text-center--xs">
                        <img src="/assets/Image/400x400/11.jpg" alt="Image5" />

                        <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                          Research Meteorologist & Updating
                        </h3>
                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                        <a
                          id="myBtn"
                          className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                          onClick={() => history.push('/research/5')}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
                <div
                  className="wow fadeInUp"
                  data-wow-duration=".3"
                  data-wow-delay=".1s"
                >
                  <div className="s-plan-v1 councellor-box research-box">
                    <div className="clearfix ">
                      <div className=" g-text-center--xs">
                        <img src="/assets/Image/400x400/12.jpg" alt="Image6" />

                        <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                          Research for Firms
                        </h3>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <a
                          id="myBtn"
                          className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                          onClick={() => history.push('/research/6')}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
