import { FC } from 'react';
import { useHistory } from 'react-router-dom';

export const Research: FC = () => {
  const history = useHistory();

  return (
    <>
      <div className="s-swiper js__swiper-one-item">
        <div className="swiper-wrapper">
          <div className="carousel-Width">
            <>
              <div className="g-fullheight--xs g-bg-position--center swiper-slide banner-image2">
                <div className="container g-text-center--xs g-ver-center--xs">
                  <div className="g-margin-b-30--xs">
                    <h2 className="g-font-size-35--xs g-font-size-45--sm g-font-size-55--md g-color--white">
                      Research & Development
                    </h2>
                  </div>
                </div>
              </div>
              <a
                href="#research-container"
                className="s-scroll-to-section-v1--bc g-margin-b-15--xs"
              >
                <span className="g-font-size-18--xs g-color--white ti-angle-double-down"></span>
                <p className="text-uppercase g-color--white g-letter-spacing--3 g-margin-b-0--xs">
                  Learn More
                </p>
              </a>
            </>
          </div>
        </div>
        <button
          type="button"
          onClick={() => history.push('/ventingstation')}
          className="s-swiper__arrow-v1--left s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-left js__swiper-btn--prev"
        />
        <button
          type="button"
          onClick={() => history.push('/assessment')}
          className="s-swiper__arrow-v1--right s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-right js__swiper-btn--next"
        />
      </div>
      <div className="cont_bg" id="research-container">
        <div className="container g-padding-y-80--xs g-padding-y-125--sm g-margin-b-25--xs">
          <h2 className="g-font-size-32--xs g-font-size-36--sm g-margin-b-25--xs text-center">
            Research
          </h2>
          <p className="text-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <div className="row g-row-col--15">
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/07.jpg" alt="Image1" />
                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Profile
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/research/1')}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/08.jpg" alt="Image2" />

                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Help Us Research
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/research/2')}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/09.jpg" alt="Image2" />

                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Variables
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/research/3')}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/10.jpg" alt="Image4" />

                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Research Articles
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.Lorem Ipsum is simply dummy text of
                        the printing.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/research/4')}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/11.jpg" alt="Image5" />

                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Research Meteorologist & Updating
                      </h3>
                      <p>Lorem Ipsum is simply dummy text of the printing.</p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/research/5')}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/12.jpg" alt="Image" />

                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Research for Firms
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/research/6')}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
