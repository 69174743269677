import { FC } from 'react';
import { useHistory } from 'react-router-dom';

export const Assessment: FC = () => {
  const history = useHistory();
  return (
    <>
      <div className="s-swiper js__swiper-one-item">
        <div className="swiper-wrapper">
          <div className="carousel-Width">
            <>
              <div className="g-fullheight--xs g-bg-position--center swiper-slide banner-image3">
                <div className="container g-text-center--xs g-ver-center--xs">
                  <div className="g-margin-b-30--xs">
                    <h2 className="g-font-size-35--xs g-font-size-45--sm g-font-size-55--md g-color--white">
                      Self Assessment
                    </h2>
                  </div>
                </div>
              </div>
              <a
                href="#self-assessment"
                className="s-scroll-to-section-v1--bc g-margin-b-15--xs"
              >
                <span className="g-font-size-18--xs g-color--white ti-angle-double-down"></span>
                <p className="text-uppercase g-color--white g-letter-spacing--3 g-margin-b-0--xs">
                  Learn More
                </p>
              </a>
            </>
          </div>
        </div>
        <button
          type="button"
          onClick={() => history.push('/research')}
          className="s-swiper__arrow-v1--left s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-left js__swiper-btn--prev"
        />
        <button
          type="button"
          onClick={() => history.push('/')}
          className="s-swiper__arrow-v1--right s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-right js__swiper-btn--next"
        />
      </div>
      <div className="cont_bg" id="self-assessment">
        <div className="container g-padding-y-80--xs g-padding-y-125--sm g-margin-b-25--xs">
          <div className="profile-outer g-margin-b-60--xs">
            <div className="row g-hor-centered-row--md">
              <div className="col-md-12 g-hor-centered-row__col g-margin-b-60--xs g-margin-b-0--md text-center">
                <div className="g-width-100-percent--xs g-margin-b-40--xs">
                  <h2 className="g-font-size-32--xs g-font-size-36--md g-font-family--playfair g-margin-b-20--xs">
                    Self Assessment
                  </h2>
                  <p className="g-font-size-16--sm">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-row-col--15">
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/01.jpg" alt="Image1" />
                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Depression
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/assessment/1')}
                      >
                        Take Test
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/02.jpg" alt="Image2" />

                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Personality Disorders
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/assessment/2')}
                      >
                        Take Test
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 g-margin-b-10--xs g-margin-b-15--lg g-padding-y-10--xs g-text-center--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="s-plan-v1 councellor-box research-box">
                  <div className="clearfix ">
                    <div className=" g-text-center--xs">
                      <img src="/assets/Image/400x400/03.jpg" alt="Image3" />

                      <h3 className="g-font-size-16--xs g-margin-b-20--xs font-weight-bold g-margin-t-30--xs">
                        Anxiety Disorders
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <a
                        id="myBtn"
                        className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50"
                        onClick={() => history.push('/assessment/3')}
                      >
                        Take Test
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
