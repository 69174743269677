import { FC, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const enum MenuHash {
  Home = '#home',
  About = '#about',
  OurMission = '#ourmission',
  Contacts = '#contacts',
}

export const Home: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const scollToHomeRef = useRef<any>();
  const scollToAboutRef = useRef<any>();
  const scollToOurMissionRef = useRef<any>();
  const scollToContactsRef = useRef<any>();

  useEffect(() => {
    if (location.hash) {
      switch (location.hash) {
        case MenuHash.Home:
          scollToHomeRef?.current?.scrollIntoView();
          break;
        case MenuHash.About:
          scollToAboutRef?.current?.scrollIntoView();
          break;
        case MenuHash.OurMission:
          scollToOurMissionRef?.current?.scrollIntoView();
          break;
        case MenuHash.Contacts:
          scollToContactsRef?.current?.scrollIntoView();
          break;
      }
    }
  }, [location.hash]);

  return (
    <>
      <div className="s-swiper js__swiper-one-item">
        <div className="swiper-wrapper" id="home" ref={scollToHomeRef}>
          <div className="carousel-Width">
            <>
              <div className="g-fullheight--xs g-bg-position--center swiper-slide banner-image1">
                <div className="container g-text-center--xs g-ver-center--xs">
                  <div
                    className="g-margin-b-30--xs"
                    style={{ textAlign: 'left' }}
                  >
                    <div className="g-margin-b-30--xs">
                      <p className="g-font-size-18--xs g-font-size-22--sm g-color--white-opacity">
                        Yew got This{' '}
                      </p>
                      <h2 className="g-font-size-35--xs g-font-size-45--sm g-font-size-55--md g-color--white">
                        Advancing mental <br />
                        health to <br />
                        new heights
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="#about"
                className="s-scroll-to-section-v1--bc g-margin-b-15--xs"
              >
                <span className="g-font-size-18--xs g-color--white ti-angle-double-down"></span>
                <p className="text-uppercase g-color--white g-letter-spacing--3 g-margin-b-0--xs">
                  Learn More
                </p>
              </a>
            </>
          </div>
        </div>
        <button
          type="button"
          onClick={() => history.push('/assessment')}
          className="s-swiper__arrow-v1--left s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-left js__swiper-btn--prev"
        />
        <button
          type="button"
          onClick={() => history.push('/councelor')}
          className="s-swiper__arrow-v1--right s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-right js__swiper-btn--next"
        />
      </div>
      <div className="container g-padding-y-80--xs g-padding-y-125--sm">
        <div
          className="row g-hor-centered-row--md g-row-col--5 g-margin-b-60--xs g-margin-b-100--md"
          id="about"
          ref={scollToAboutRef}
        >
          <div className="col-sm-3 col-xs-6 g-hor-centered-row__col">
            <div
              className="wow fadeInLeft"
              data-wow-duration=".3"
              data-wow-delay=".1s"
            >
              <img
                className="img-responsive"
                src="/assets/Image/400x500/05.jpg"
                alt="image5"
              />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6 g-hor-centered-row__col g-margin-b-60--xs g-margin-b-0--md">
            <div
              className="wow fadeInLeft"
              data-wow-duration=".3"
              data-wow-delay=".2s"
            >
              <img
                className="img-responsive"
                src="/assets/Image/400x550/01.jpg"
                alt="image1"
              />
            </div>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-5 g-hor-centered-row__col">
            <h2 className="g-font-size-32--xs g-font-size-36--sm g-margin-b-25--xs">
              About
            </h2>
            <p className="g-font-size-18--xs">
              We are an online portal that offers consumers direct access to
              emotional support platforms designed to foster mental health. Our
              ervices are delivered via web-based interaction as well as phone
              and text communication, making them available to anyone on the
              planet.
            </p>
            <p className="g-font-size-18--xs">
              We believe in the notion that mental illness is not a personal
              failure, It is just as important as physical health.
            </p>
            <p className="g-font-size-18--xs">
              Here, we push the boundaries of psychology and mental wellbeing to
              make it more accessible, affordable, and convenient for everybody.
            </p>
          </div>
        </div>

        <div
          className="row g-hor-centered-row--md g-row-col--5"
          id="ourmission"
          ref={scollToOurMissionRef}
        >
          <div className="col-sm-3 col-xs-6 col-sm-push-6 g-hor-centered-row__col">
            <div
              className="wow fadeInRight"
              data-wow-duration=".3"
              data-wow-delay=".2s"
            >
              <img
                className="img-responsive"
                src="/assets/Image/400x550/02.jpg"
                alt="image2"
              />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6 col-sm-push-6 g-hor-centered-row__col g-margin-b-60--xs g-margin-b-0--md">
            <div
              className="wow fadeInRight"
              data-wow-duration=".3"
              data-wow-delay=".1s"
            >
              <img
                className="img-responsive"
                src="/assets/Image/400x500/06.jpg"
                alt="image6"
              />
            </div>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-5 col-sm-pull-7 g-hor-centered-row__col g-text-left--xs g-text-right--md">
            <h2 className="g-font-size-32--xs g-font-size-36--sm g-margin-b-25--xs">
              Our Mission
            </h2>
            <p className="g-font-size-18--sm">
              Our goal is to promote psychology in its purest form and to
              de-stigmatize mental illness. We are a committed community of
              people who advocate emotional well-being to anybody who is going
              through tough times in their life.
            </p>
          </div>
        </div>
      </div>
      <div
        className="g-promo-section g-bg-color--sky-light"
        id="contacts"
        ref={scollToContactsRef}
      >
        <div className="container g-padding-y-80--xs g-padding-y-125--sm">
          <div className="row">
            <div className="col-md-4 g-margin-t-15--xs g-margin-b-60--xs g-margin-b-0--lg">
              <p className="text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2 g-margin-b-25--xs">
                Event Management
              </p>
              <div
                className="wow fadeInLeft"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <h2 className="g-font-size-40--xs g-font-size-50--sm g-font-size-60--md">
                  Our Services
                </h2>
              </div>
            </div>
            <div className="col-md-4 col-md-offset-1">
              <p className="g-font-size-18--xs">
                We give our utmost priority to sharing, connecting and learning
                valuable information here. Users can participate in any
                forthcoming activities by visiting this section of our website.
                We organize webinars, seminars, workshops, tuitions, coaching
                classes and conferences led by renowned and prominent speakers
                from India and around the world. The user can view upcoming
                event information such as the event deadline, seat availability,
                mode of instruction, contact details, and book their slot as
                well as learn from the comfort of their own home.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 g-promo-section__img-right--lg g-bg-position--center g-height-100-percent--md js__fullwidth-img">
          <img
            className="img-responsive"
            src="/assets/Image/970x970/03.jpg"
            alt="Image3"
          />
        </div>
      </div>
    </>
  );
};
