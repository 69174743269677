import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const UpcomingEvents: FC = () => {
  const history = useHistory();
  const [isPopup, setIsPopup] = useState<boolean>(false);
  return (
    <>
      <div className="s-swiper js__swiper-one-item">
        <div className="swiper-wrapper">
          <div className="carousel-Width">
            <>
              <div className="g-fullheight--xs g-bg-position--center swiper-slide banner-image3">
                <div className="container g-text-center--xs g-ver-center--xs">
                  <div className="g-margin-b-30--xs">
                    <h2 className="g-font-size-35--xs g-font-size-45--sm g-font-size-55--md g-color--white">
                      Our Upcoming Events
                    </h2>
                  </div>
                </div>
              </div>
              <a
                href="#upcoming"
                className="s-scroll-to-section-v1--bc g-margin-b-15--xs"
              >
                <span className="g-font-size-18--xs g-color--white ti-angle-double-down"></span>
                <p className="text-uppercase g-color--white g-letter-spacing--3 g-margin-b-0--xs">
                  Learn More
                </p>
              </a>
            </>
          </div>
        </div>
        <button
          type="button"
          onClick={() => history.push('/councelor')}
          className="s-swiper__arrow-v1--left s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-left js__swiper-btn--prev"
        />
        <button
          type="button"
          onClick={() => history.push('/events')}
          className="s-swiper__arrow-v1--right s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-right js__swiper-btn--next"
        />
      </div>
      <div className="cont_bg" id="upcoming">
        <div className="container g-padding-y-80--xs g-padding-y-125--sm g-margin-b-25--xs">
          <div className="g-text-center--xs g-margin-b-20--xs g-margin-t-20--xs">
            <h2 className="g-font-size-32--xs g-font-size-36--md">
              Upcoming Event
            </h2>
          </div>
          <div className="row g-row-col--5">
            <div className="col-md-12 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="event-mngmnt">
                  <div className="img-outer">
                    <img src="/assets/Image/400x400/01.jpg" alt="Image1" />
                  </div>
                  <div className="txt-outer">
                    <h3 className="g-font-size-18--xs">About The Event</h3>
                    <p className="g-margin-b-0--xs g-margin-b-10--xs">
                      The time has come to bring those ideas and plans to life.
                      This is where we really begin to visualize your napkin
                      sketches and make them into beautiful pixels. The time has
                      come to bring those ideas and plans to life. This is where
                      we really begin to visualize your napkin sketches and make
                      them into beautiful pixels.
                    </p>

                    <div className="bottom">
                      <div className="event-outer">
                        <img
                          src="/assets/Image/calendar-icon.svg"
                          alt="calendar"
                        />{' '}
                        10:30 AM, 07/05/2022
                      </div>
                      <div className="btt-outer">
                        <a
                          id="myBtn"
                          onClick={() => setIsPopup(true)}
                          className="primary-btt"
                        >
                          Book Now
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="event-mngmnt">
                  <div className="img-outer">
                    <img src="/assets/Image/400x400/02.jpg" alt="Image1" />
                  </div>
                  <div className="txt-outer">
                    <h3 className="g-font-size-18--xs">About The Event</h3>
                    <p className="g-margin-b-0--xs g-margin-b-10--xs">
                      The time has come to bring those ideas and plans to life.
                      This is where we really begin to visualize your napkin
                      sketches and make them into beautiful pixels. The time has
                      come to bring those ideas and plans to life. This is where
                      we really begin to visualize your napkin sketches and make
                      them into beautiful pixels.
                    </p>

                    <div className="bottom">
                      <div className="event-outer">
                        <img
                          src="/assets/Image/calendar-icon.svg"
                          alt="calendar"
                        />{' '}
                        10:30 AM, 07/05/2022
                      </div>
                      <div className="btt-outer">
                        <a
                          id="myBtn"
                          onClick={() => setIsPopup(true)}
                          className="primary-btt"
                        >
                          Book Now
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="event-mngmnt">
                  <div className="img-outer">
                    <img src="/assets/Image/400x400/03.jpg" alt="Image2" />
                  </div>
                  <div className="txt-outer">
                    <h3 className="g-font-size-18--xs">About The Event</h3>
                    <p className="g-margin-b-0--xs g-margin-b-10--xs">
                      The time has come to bring those ideas and plans to life.
                      This is where we really begin to visualize your napkin
                      sketches and make them into beautiful pixels. The time has
                      come to bring those ideas and plans to life. This is where
                      we really begin to visualize your napkin sketches and make
                      them into beautiful pixels.
                    </p>

                    <div className="bottom">
                      <div className="event-outer">
                        <img
                          src="/assets/Image/calendar-icon.svg"
                          alt="calendar"
                        />{' '}
                        10:30 AM, 07/05/2022
                      </div>
                      <div className="btt-outer">
                        <a
                          id="myBtn"
                          onClick={() => setIsPopup(true)}
                          className="primary-btt"
                        >
                          Book Now
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="col-md-12 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="event-mngmnt">
                  <div className="img-outer">
                    <img src="/assets/Image/400x400/04.jpg" alt="Image3" />
                  </div>
                  <div className="txt-outer">
                    <h3 className="g-font-size-18--xs">About The Event</h3>
                    <p className="g-margin-b-0--xs g-margin-b-10--xs">
                      The time has come to bring those ideas and plans to life.
                      This is where we really begin to visualize your napkin
                      sketches and make them into beautiful pixels. The time has
                      come to bring those ideas and plans to life. This is where
                      we really begin to visualize your napkin sketches and make
                      them into beautiful pixels.
                    </p>

                    <div className="bottom">
                      <div className="event-outer">
                        <img
                          src="/assets/Image/calendar-icon.svg"
                          alt="calendar"
                        />{' '}
                        10:30 AM, 07/05/2022
                      </div>
                      <div className="btt-outer">
                        <a
                          id="myBtn"
                          onClick={() => setIsPopup(true)}
                          className="primary-btt"
                        >
                          Book Now
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="col-md-12 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="event-mngmnt">
                  <div className="img-outer">
                    <img src="/assets/Image/400x400/05.jpg" alt="Image4" />
                  </div>
                  <div className="txt-outer">
                    <h3 className="g-font-size-18--xs">About The Event</h3>
                    <p className="g-margin-b-0--xs g-margin-b-10--xs">
                      The time has come to bring those ideas and plans to life.
                      This is where we really begin to visualize your napkin
                      sketches and make them into beautiful pixels. The time has
                      come to bring those ideas and plans to life. This is where
                      we really begin to visualize your napkin sketches and make
                      them into beautiful pixels.
                    </p>

                    <div className="bottom">
                      <div className="event-outer">
                        <img
                          src="/assets/Image/calendar-icon.svg"
                          alt="calendar"
                        />{' '}
                        10:30 AM, 07/05/2022
                      </div>
                      <div className="btt-outer">
                        <a
                          id="myBtn"
                          onClick={() => setIsPopup(true)}
                          className="primary-btt"
                        >
                          Book Now
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="col-md-12 g-margin-b-10--xs g-margin-b-0--lg g-padding-y-5--xs">
              <div
                className="wow fadeInUp"
                data-wow-duration=".3"
                data-wow-delay=".1s"
              >
                <div className="event-mngmnt">
                  <div className="img-outer">
                    <img src="/assets/Image/400x400/06.jpg" alt="Image5" />
                  </div>
                  <div className="txt-outer">
                    <h3 className="g-font-size-18--xs">About The Event</h3>
                    <p className="g-margin-b-0--xs g-margin-b-10--xs">
                      The time has come to bring those ideas and plans to life.
                      This is where we really begin to visualize your napkin
                      sketches and make them into beautiful pixels. The time has
                      come to bring those ideas and plans to life. This is where
                      we really begin to visualize your napkin sketches and make
                      them into beautiful pixels.
                    </p>

                    <div className="bottom">
                      <div className="event-outer">
                        <img
                          src="/assets/Image/calendar-icon.svg"
                          alt="calendar"
                        />{' '}
                        10:30 AM, 07/05/2022
                      </div>
                      <div className="btt-outer">
                        <a
                          id="myBtn"
                          onClick={() => setIsPopup(true)}
                          className="primary-btt"
                        >
                          Book Now
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myModal" className={`modal ${isPopup ? 'modal-active' : ''}`}>
          <div className="modal-content container">
            <span
              className="close"
              aria-hidden
              onClick={() => setIsPopup(false)}
            >
              &times;
            </span>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="g-width-100-percent--xs">
                  <h2 className="g-font-size-32--xs g-font-size-36--md g-margin-b-20--xs">
                    About the Speakers
                  </h2>
                  <p className="g-font-size-20--sm">
                    Now that we've aligned the details, it's time to get things
                    mapped out and organized. This part is really crucial in
                    keeping the project in line to completion.
                  </p>
                  <p className="g-font-size-20--sm">
                    The time has come to bring those ideas and plans to life.
                    This is where we really begin to visualize your napkin
                    sketches and make them into beautiful pixels.
                  </p>

                  <div className="row g-margin-t-50--md">
                    <div className="col-sm-6">
                      <div className="mangmt_popup_L">
                        <div className="row">
                          <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                            Type Of Event
                          </div>
                          <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                            Seminars
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                            Date
                          </div>
                          <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                            20/04/2022
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                            Venue
                          </div>
                          <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                            TEchno Park Trivandrum
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                            Language
                          </div>
                          <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                            English, Arabic, Malayalam
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                            Fee
                          </div>
                          <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                            250
                          </div>
                        </div>

                        <a
                          onClick={() => {
                            setIsPopup(false);
                            history.push('/register');
                          }}
                          className="text-uppercase btn-block s-btn s-btn--md s-btn--primary-bg g-radius--50 g-padding-x-50--xs g-margin-b-20--xs"
                        >
                          Book Your Seat
                        </a>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="row">
                        <div className="col-sm-12 g-margin-b-30--xs g-margin-b-0--md">
                          <h4>Contact</h4>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                          Event Name
                        </div>
                        <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                          Event Name 01
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                          Time
                        </div>
                        <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                          10:30 PM
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                          Mode Of Event
                        </div>
                        <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                          English, Arabic, Malayalam
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-4 g-margin-b-30--xs g-margin-b-0--md">
                          Seat Of Capacity
                        </div>
                        <div className="col-sm-8 g-margin-b-30--xs g-margin-b-0--md popup-R-text">
                          250
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
