import { FC } from 'react';

export const OnlineCouncellor: FC = () => {
  return (
    <>
      <div className="cont_bg">
        <div className="container g-padding-y-80--xs g-padding-y-125--sm g-margin-b-25--xs">
          <div className="g-text-center--xs g-margin-b-20--xs g-margin-t-20--xs">
            <h2 className="g-font-size-32--xs g-font-size-36--md">
              Online Councellor
            </h2>
          </div>

          <div className="online-outer">
            <a href="#">
              <div className="online-inner">
                <div className="img-outer g-border-color--cout">
                  <img src="/assets/Image/400x400/01.jpg" alt="Image1" />
                  <div className="status online"></div>
                </div>
                <div className="txt-outer">Councellor Name</div>
              </div>
            </a>
            <a href="#">
              <div className="online-inner">
                <div className="img-outer g-border-color--drhosp">
                  <img src="/assets/Image/400x400/02.jpg" alt="Image2" />
                  <div className="status offline"></div>
                </div>
                <div className="txt-outer">Councellor Name</div>
              </div>
            </a>
            <a href="#">
              <div className="online-inner">
                <div className="img-outer g-border-color--fmodel">
                  <img src="/assets/Image/400x400/03.jpg" alt="Image3" />
                  <div className="status idle"></div>
                </div>
                <div className="txt-outer">Councellor Name</div>
              </div>
            </a>
            <a href="#">
              <div className="online-inner">
                <div className="img-outer g-border-color--tshrt">
                  <img src="/assets/Image/400x400/04.jpg" alt="Image4" />
                  <div className="status online"></div>
                </div>
                <div className="txt-outer">Councellor Name</div>
              </div>
            </a>
            <a href="#">
              <div className="online-inner">
                <div className="img-outer g-border-color--blksht">
                  <img src="/assets/Image/400x400/05.jpg" alt="Image5" />
                  <div className="status offline"></div>
                </div>
                <div className="txt-outer">Councellor Name</div>
              </div>
            </a>
            <a href="#">
              <div className="online-inner">
                <div className="img-outer g-border-color--blueshrt">
                  <img src="/assets/Image/400x400/06.jpg" alt="Image6" />
                  <div className="status idle"></div>
                </div>
                <div className="txt-outer">Councellor Name</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
