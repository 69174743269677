import { FC } from 'react';
import { useHistory } from 'react-router-dom';

export const VentingStation: FC = () => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push('onlinecouncellor');
  };
  return (
    <>
      <div className="s-swiper js__swiper-one-item">
        <div className="swiper-wrapper">
          <div className="carousel-Width">
            <>
              <div className="g-fullheight--xs g-bg-position--center swiper-slide banner-image5">
                <div className="container g-text-center--xs g-ver-center--xs">
                  <div className="g-margin-b-30--xs">
                    <h1 className="g-font-size-35--xs g-font-size-45--sm g-font-size-55--md g-color--white">
                      Venting Stations
                    </h1>
                    <h2>Take To A Strnger</h2>
                  </div>
                </div>
              </div>
              <a
                href="#venting-station"
                className="s-scroll-to-section-v1--bc g-margin-b-15--xs"
              >
                <span className="g-font-size-18--xs g-color--white ti-angle-double-down"></span>
                <p className="text-uppercase g-color--white g-letter-spacing--3 g-margin-b-0--xs">
                  Learn More
                </p>
              </a>
            </>
          </div>
        </div>
        <button
          type="button"
          onClick={() => history.push('/events')}
          className="s-swiper__arrow-v1--left s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-left js__swiper-btn--prev"
        />
        <button
          type="button"
          onClick={() => history.push('/research')}
          className="s-swiper__arrow-v1--right s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-right js__swiper-btn--next"
        />
      </div>

      <div
        className="g-bg-position--center g-fullheight--xs g-padding-y-80--xs venting-station-bg"
        id="venting-station"
      >
        <div className="container js__ver-center-aligned">
          <div className="g-text-center--xs">
            <div className="g-margin-t-40--xs g-margin-b-60--xs g-margin-b-80--sm">
              <h1 className="g-font-size-32--xs g-font-size-50--sm g-font-size-60--md g-margin-b-30--xs">
                Venting Station
              </h1>
              <p className="text-uppercase g-font-size-20--md g-font-weight--300">
                Be the first to know when we launch
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row g-margin-b-80--xs">
                <div className="col-sm-4 col-sm-offset-2 col-xs-6 g-full-width--xs g-margin-b-30--xs g-margin-b-0--md">
                  <div className="input-group">
                    <span className="input-group-btn">
                      <i className="s-form-v4__icon ti-user"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control s-form-v4__input"
                      placeholder="Your Name"
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-xs-6 g-full-width--xs">
                  <div className="input-group">
                    <span className="input-group-btn">
                      <i className="s-form-v4__icon ti-mobile"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control s-form-v4__input"
                      placeholder="Your Phone Number"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="text-uppercase s-btn s-btn--md s-btn--primary-bg g-radius--50 g-padding-x-50--xs g-margin-b-20--xs"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
