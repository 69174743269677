import { FC, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const Header: FC = () => {
  const [scrollPosition, setPosition] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  const headerClass = `navbar-fixed-top s-header js__header-sticky js__header-overlay ${
    scrollPosition > 60 && !isActive && 's-header__shrink'
  }`;

  const handleAction = () => {
    setIsActive(!isActive);
  };

  const headerTrigger = `s-header__trigger js__trigger ${
    isActive && '-is-active'
  }`;

  const headerOverlay = `s-header-bg-overlay js__bg-overlay ${
    isActive && '-is-open'
  }`;

  return (
    <header className={headerClass}>
      <div className="s-header__navbar">
        <div className="s-header__container">
          <div className="s-header__navbar-row">
            <div className="s-header__navbar-row-col">
              {/* Logo */}
              <div className="s-header__logo">
                <Link to="/" className="s-header__logo-link">
                  <img
                    className="s-header__logo-img s-header__logo-img-default"
                    src="/assets/Image/logo.png"
                    alt="Yew got this"
                  />
                  <img
                    className="s-header__logo-img s-header__logo-img-shrink"
                    src="/assets/Image/logo-white.png"
                    alt="Yew got this"
                  />
                </Link>
              </div>
              {/* End Logo */}
            </div>
            <div className="s-header__navbar-row-col">
              {/* Trigger */}
              <div className={headerTrigger}>
                <span className="s-header__trigger-icon" />
                <svg
                  x="0rem"
                  y="0rem"
                  width="3.125rem"
                  height="3.125rem"
                  viewBox="0 0 54 54"
                >
                  <circle
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="1"
                    cx="27"
                    cy="27"
                    r="25"
                    stroke-dasharray="157 157"
                    stroke-dashoffset="157"
                    onClick={handleAction}
                  />
                </svg>
              </div>
              {/* End Trigger */}
            </div>
          </div>
        </div>
      </div>
      {/* End Navbar */}

      {/* Overlay */}
      <div className={headerOverlay}>
        {/* Nav */}
        <nav className="s-header__nav js__scrollbar">
          <div className="container-fluid">
            {/* Menu List */}
            <ul className="list-unstyled s-header__nav-menu">
              <li className="s-header__nav-menu-item">
                <Link
                  className="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  to={{
                    pathname: '/',
                    hash: '#home',
                  }}
                  onClick={handleAction}
                >
                  Home
                </Link>
              </li>
              <li className="s-header__nav-menu-item">
                <Link
                  className="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  to={{
                    pathname: '/',
                    hash: '#about',
                  }}
                  onClick={handleAction}
                >
                  About
                </Link>
              </li>
              <li className="s-header__nav-menu-item">
                <Link
                  replace
                  className="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  to={{
                    pathname: '/',
                    hash: '#ourmission',
                  }}
                  onClick={handleAction}
                >
                  Our Mission
                </Link>
              </li>
              <li className="s-header__nav-menu-item">
                <Link
                  className="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  to={{
                    pathname: '/',
                    hash: '#contacts',
                  }}
                  onClick={handleAction}
                >
                  Contacts
                </Link>
              </li>
            </ul>
            {/* End Menu List */}
          </div>
        </nav>
        {/* End Nav */}

        {/* Action */}
        <ul className="list-inline s-header__action s-header__action--lb">
          <li className="s-header__action-item">
            <div className="s-header__action-link -is-active">En</div>
          </li>
          <li className="s-header__action-item">
            <div className="s-header__action-link">Fr</div>
          </li>
        </ul>
        {/* End Action */}

        {/* Action */}
        <ul className="list-inline s-header__action s-header__action--rb">
          <li className="s-header__action-item">
            <div className="s-header__action-link">
              <i className="g-padding-r-5--xs ti-facebook"></i>
              <span className="g-display-none--xs g-display-inline-block--sm">
                Facebook
              </span>
            </div>
          </li>
          <li className="s-header__action-item">
            <div className="s-header__action-link">
              <i className="g-padding-r-5--xs ti-twitter"></i>
              <span className="g-display-none--xs g-display-inline-block--sm">
                Twitter
              </span>
            </div>
          </li>
          <li className="s-header__action-item">
            <div className="s-header__action-link">
              <i className="g-padding-r-5--xs ti-instagram"></i>
              <span className="g-display-none--xs g-display-inline-block--sm">
                Instagram
              </span>
            </div>
          </li>
        </ul>
        {/* End Action */}
      </div>
      {/* End Overlay */}
    </header>
  );
};
