import { FC } from 'react';

export const Footer: FC = () => {
  return (
    <footer className="g-bg-color--dark">
      <div className="container g-padding-y-50--xs">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-xs-12">
            <a href="/">
              <img
                className="g-width-100--xs g-height-auto--xs"
                src="/assets/Image/logo-white.png"
                alt="Yew got this"
              />
            </a>
          </div>

          <div className="col-lg-4 col-md-4 col-xs-12 g-text-left--xs">
            <p className="g-font-size-14--xs g-margin-b-0--xs g-color--white-opacity-light">
              <a href="/">Yewgotthis</a> Theme Powered by:{' '}
              <a href="livosys.com">livosys.com</a>
            </p>
          </div>

          <div className="col-lg-4 col-md-4 col-xs-12 g-text-left--xs">
            <p className="g-font-size-14--xs g-margin-b-0--xs g-color--white-opacity-light">
              <a href="/">Yewgotthis</a> Theme Distributed by:{' '}
              <a href="https://livosys.com/" target={'_blank'} rel="noreferrer">
                livosys.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
