import { FC, useState } from 'react';

export const AssessmentQuestion: FC = () => {
  const [isEnabledResult, setIsEnabledResult] = useState(false);
  return (
    <>
      <div className="cont_bg">
        <div className="container g-padding-y-80--xs g-padding-y-125--sm g-margin-b-25--xs">
          <div className="profile-outer g-margin-b-60--xs g-margin-t-30--xs">
            <h2 className=" g-font-size-32--xs g-font-size-36--md g-margin-b-40--xs text-center">
              MCQ Questions
            </h2>

            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>
            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>
            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>
            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>
            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>
            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>
            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>
            <div className="g-margin-b-40--xs">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable?
              </h4>
              <div>
                <input
                  type="text"
                  name=""
                  className="form-control s-form-v6__input"
                  placeholder="Answer"
                />
              </div>
            </div>

            <a
              href="#show-your-score"
              className="text-uppercase s-btn s-btn--xs g-text-center--xs s-btn--primary-bg g-radius--50 g-margin-t-20--xs"
              onClick={() => setIsEnabledResult(true)}
            >
              Show Your Score
            </a>
          </div>
          {isEnabledResult && (
            <div className="profile-outer">
              <div className="row g-hor-centered-row--md">
                <div className="col-md-6 g-margin-b-30--xs">
                  <div className="row">
                    <div className="col-md-4">Scale Name</div>
                    <div className="col-md-8 g-font-size-16--xs font-weight-bold">
                      John Smith
                    </div>
                  </div>
                </div>
                <div className="col-md-6 g-margin-b-30--xs">
                  <div className="row">
                    <div className="col-md-4">Score</div>
                    <div className="col-md-8 g-font-size-16--xs font-weight-bold">
                      324
                    </div>
                  </div>
                </div>
                <div className="col-md-6 g-margin-b-20--xs">
                  <div className="row">
                    <div className="col-md-4">Interpretation</div>
                    <div className="col-md-8 g-font-size-16--xs font-weight-bold">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
                <div className="col-md-6 g-margin-b-20--xs">
                  <div className="row">
                    <div className="col-md-4">Contact Us</div>
                    <div className="col-md-8 g-font-size-16--xs font-weight-bold">
                      9856742364, johnsmith@gmail.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
