import { FC } from 'react';

export const Register: FC = () => {
  return (
    <div className="g-position--relative g-bg-color--primary">
      <div className="g-container--md g-padding-y-125--xs">
        <div className="g-text-center--xs g-margin-b-80--xs">
          <h2 className="g-font-size-32--xs g-font-size-36--md">Register</h2>
        </div>
        <div className="row g-row-col--5 g-margin-b-80--xs">
          <div className="col-xs-6 g-full-width--xs g-margin-b-50--xs g-margin-b-0--sm">
            <div className="g-text-center--xs">
              <i className="g-display-block--xs g-font-size-40--xs g-color--white-opacity g-margin-b-30--xs ti-timer"></i>
              <h4 className="g-font-size-18--xs g-color--white g-margin-b-5--xs">
                08 : 20 : 19
              </h4>
              <p className="g-color--white-opacity">remaining Time</p>
            </div>
          </div>
          <div className="col-xs-6 g-full-width--xs g-margin-b-50--xs g-margin-b-0--sm">
            <div className="g-text-center--xs">
              <i className="g-display-block--xs g-font-size-40--xs g-color--white-opacity g-margin-b-30--xs ti-wheelchair"></i>
              <h4 className="g-font-size-18--xs g-color--white g-margin-b-5--xs">
                455
              </h4>
              <p className="g-color--white-opacity">remaining Seate</p>
            </div>
          </div>
        </div>
        <form className="center-block g-width-500--sm g-width-550--md">
          <div className="g-margin-b-30--xs">
            <input
              type="text"
              className="form-control s-form-v3__input"
              placeholder="* Name"
            />
          </div>
          <div className="row g-row-col-5 g-margin-b-50--xs">
            <div className="col-sm-6 g-margin-b-30--xs g-margin-b-0--md">
              <input
                type="text"
                className="form-control s-form-v3__input"
                placeholder="* Age"
              />
            </div>
            <div className="col-sm-6">
              <select className="form-control s-form-v3__select" name="Gender">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Transgender">Transgender</option>
              </select>
            </div>
          </div>
          <div className="g-text-center--xs">
            <a className="text-uppercase s-btn s-btn--md s-btn--white-bg g-radius--50 g-padding-x-70--xs g-margin-b-20--xs">
              Book A Seat
            </a>
          </div>
        </form>
      </div>
      <img
        className="s-mockup-v2"
        src="/assets/Image/mockups/pencil-01.png"
        alt="Mockup Image"
      />
    </div>
  );
};
