import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from '../Footer';
import { Header } from '../Header';

export const BaseAppLayout: FC = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
